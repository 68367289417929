import * as React from "react"

import Layout from "../../components/Layout"
import HeroImageAbout from "../../components/AboutPage/HeroImageAbout"
import Update from "../../components/Newsletter/Update"
import SentimentPageNewsletter from "../../components/LuxurySentimentPage/SentimentPageNewsletter"


function updateprefereneces({ data }) {
  return (
    <Layout>
      <Update />

    </Layout>
  )
}

export default updateprefereneces
