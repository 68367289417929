import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import "../scss/HeroImageAbout.scss"
import { StaticImage } from 'gatsby-plugin-image';

export default function HeroImageAbout() {
    const data = useStaticQuery(graphql`
    query MyQuery19 {
      allContentfulHeroImage(filter: { name: { eq: "About Page Hero Image" } }) {
        edges {
          node {
            heroImage {
              altText
              image {
                file {
                  url
                }
                id
              }
              name
            }
          }
        }
      }
    }
  `)

    return (
        <div className="heroImageAboutContainer">
            {/* <img
              className={"heroImageAbout"}
              alt={data.allContentfulHeroImage.edges[0].node.heroImage.altText}
              key={``} 
              src={data.allContentfulHeroImage.edges[0].node.heroImage.image.file.url}
            /> */}
            <StaticImage
              className={"heroImageAbout"}
              src="https://images.ctfassets.net/x5jqyuhgi4rx/1O0v2c2TZRyXonsgfZc2Wm/7f8d8fe76654f8ad90aa0522ad99798b/About_Me_Page.webp"
              alt={data.allContentfulHeroImage.edges[0].node.heroImage.altText}
              width={6000}
              height={4000}
              loading="lazy"
            />
        </div>
    )
}
