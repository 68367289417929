import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import "../scss/Update.scss";
import { Helmet } from "react-helmet";

export default function Thanks() {
  const data = useStaticQuery(graphql`
    query MyQuery3791 {
      allContentfulHeroImage(filter: { name: { eq: "Update Preferences" } }) {
        edges {
          node {
            heroImage {
              altText
              image {
                file {
                  url
                }
                id
              }
              name
            }
          }
        }
      }
    }
  `);

  return (
    <div className="UpdatePageContainer">
      <div className="contentContainerUpdate">
        <div className="imageContainerUpdate">
          <img className="Update" src={data.allContentfulHeroImage.edges[0].node.heroImage.image.file.url} />
        </div>
        <div className="UpdateContainer">
            <p className="UpdateTitle">Bespoke Insights</p>
            <p className="UpdateSubTitle">Update LUXE Preferences</p>
            <p className="UpdateText">To update your preferences submit a request below and you’ll receive an email with options.</p>
            <p className="UpdateText">The link we send you lasts for a few hours. So resubmit this request if needed.</p>
            <div className="UpdateSubmit">
              <input type="submit" value="Submit" />
            </div>
        </div>
      </div>
    </div>

  );
}

