import React, { useState } from 'react';
import "../scss/SentimentPageNewsletter.scss";
import { Helmet } from "react-helmet";

const StyledForm = () => {
  const [occupation, setOccupation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedRadioValue, setSelectedRadioValue] = useState("");
  const [isEmailInputEnabled, setIsEmailInputEnabled] = useState(false);

  const handleOccupationChange = (e) => {
    setOccupation(e.target.value);
    setErrorMessage(''); // Clear error message when a radio button is selected
  };

  const handleEmailInputChange = (e) => {
    if (errorMessage) {
      setErrorMessage(''); // Clear the error message when the user starts typing a different email
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get('MERGE0');
    console.log("Selected Radio Button Value: ", selectedRadioValue);
    console.log("Email: ", email);
  
    const emailInput = document.querySelector('input[type="email"]');
    const enteredEmail = emailInput.value.trim().toLowerCase();
  
    const disallowedDomains = ['@gmail', '@yahoo', '@icloud', '@aol', '@mac', '@hotmail', '@outlook', '@proton', '@protonmail', '@zohocorp', '@gmx', '@yandex', '@hubspot', '@neo', '@thunder', '@degcos', '@me'];
    const disallowedPrefixes = ['info', 'contact']; // Disallowed prefixes
  
    const containsDisallowedDomain = disallowedDomains.some(domain => enteredEmail.includes(domain));
    const emailPrefix = enteredEmail.split('@')[0]; // Extract the part before '@'
    const containsDisallowedPrefix = disallowedPrefixes.some(prefix => emailPrefix === prefix);
  
    if (!occupation) {
      setErrorMessage('Please select an occupation');
      return;
    } else if (containsDisallowedDomain) {
      setErrorMessage('Please use your business email');
      console.error('Disallowed email domain used:', enteredEmail);
      return;
    } else if (containsDisallowedPrefix) {
      setErrorMessage('Please use your business email');
      console.error('Disallowed email prefix used:', enteredEmail);
      return;
    }
  
    // If validation passes, proceed with form submission
    setErrorMessage('');
    console.log('Form submitted successfully!');
    e.target.submit(); // Manually trigger form submission
  };
  
  
  
  return (
    <>
      <Helmet>
        <h2>De-Risk Luxury Investments – Grow Luxury Revenue and Profits</h2>
      </Helmet>
    <div className='sentimentForm'>
      <p className="formTitle">De-Risk Luxury Investments - Grow Luxury Revenue and Profits</p>
      <p className="formSubtitle">Make an Enquiry</p>
      <form action="https://thefreshkid.us8.list-manage.com/subscribe/post" method="POST" target="_blank" onSubmit={handleSubmit}>
          <input type="hidden" name="u" value="8abd2b2ed117ea16fd4a66f6d" />
          <input type="hidden" name="id" value="287d0e6057" />
        <div className="title-section">
          <label htmlFor="title">*Title: </label>
          <br></br>
          <label htmlFor="title">Select... </label>
            <select name="MMERGE4" id="title" required>
            <option value=""></option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
            </select><br /><br />
          </div>
        <label htmlFor="firstname">*First Name:</label>
        <input type="text" name="FNAME" required/><br /><br />
        <label htmlFor="lastname">*Last Name:</label>
        <input type="text" name="LNAME" required/><br /><br />
        <label htmlFor="lastname">*Business Name:</label>
        <input type="text" name="MMERGE5" required/><br /><br />
        <label htmlFor="businessemail">*Business Email:</label>
        <input type="email" name="MERGE0" onChange={handleEmailInputChange} required></input>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <br /><br />




        <label htmlFor="phonennumber">Phone Number:</label>
        <input type="number" name="MMERGE3"/><br /><br />
        <label htmlFor="occupation"><em>*Please select your occupation</em></label>
        <br /><br />
        <div className="radio-labels">
              <label classname= "radio1" htmlFor="luxuryfundmanager">
                <input
                  type="radio"
                  name="MMERGE7"
                  id="luxuryfundmanager"
                  value="Luxury Fund Manager"
                  onChange={handleOccupationChange}
                  required
                />
                <span class="spacer">&nbsp;&nbsp;</span>Luxury Fund Manager
              </label>
              <label classname= "radio2" htmlFor="luxuryleader">
                <input
                  type="radio"
                  name="MMERGE7"
                  id="luxuryleader"
                  value="Luxury Leader"
                  onChange={handleOccupationChange}
                  required
                />
                <span class="spacer">&nbsp;&nbsp;</span>Luxury Leader
              </label>
              <label classname= "radio3" htmlFor="other">
                <input
                  type="radio"
                  name="MMERGE7"
                  id="other"
                  value="Other"
                  onChange={handleOccupationChange}
                  required
                />
                <span class="spacer">&nbsp;&nbsp;</span>Other
              </label>
            </div>
        <br></br>
        <br></br>
        <label htmlFor="message"><em>*Please share details of your investment or business challenge:</em></label>
        <br></br>
        <br></br>
        <textarea name="MMERGE6" id="message" class="responsive-textarea" required></textarea>

        <br></br>
        <label htmlFor="other"><em>*Required Information</em></label>
        <br></br>
        <br></br>
        <label htmlFor="other">TFK will only use your personal information in order to respond to your inquiry</label>
        <br></br>
        <label htmlFor="other" class="privacy-label">
            Please consult our <a href="/PrivacyPolicy" class="privacy-link">Privacy Policy</a> for more information
        </label>

        <br></br>
        <br></br>
        <br></br>
        <input type="submit" value="Submit Enquiry" />
        <br></br>
        <br></br>
      </form>
    </div>
    </>
  )
}

export default StyledForm;


